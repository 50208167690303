exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-portfolio-js": () => import("./../../../src/pages/en/portfolio.js" /* webpackChunkName: "component---src-pages-en-portfolio-js" */),
  "component---src-pages-hakkimda-js": () => import("./../../../src/pages/hakkimda.js" /* webpackChunkName: "component---src-pages-hakkimda-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolyo-js": () => import("./../../../src/pages/portfolyo.js" /* webpackChunkName: "component---src-pages-portfolyo-js" */),
  "component---src-pages-tr-js": () => import("./../../../src/pages/tr.js" /* webpackChunkName: "component---src-pages-tr-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

